<template>
<!-- https://eu-west-1.console.aws.amazon.com/wellarchitected/home?region=eu-west-1#/workload/97a4b7279109c6add95d653d6faf88a7 -->
  <CRow>
    <CCol col="12" xl="8">
      <CCard>
        <CCardHeader >
          <CButton class="float-left" disabled variant="ghost" color="dark">Workloads</CButton>
        </CCardHeader>      
        <CCardBody>         
          <CDataTable
            hover
            striped
            sorter            
            column-filter
            items-per-page-select
            :sorter-value="{ column: 'UpdatedAt', asc: 'true' }"
            :items="workloads"
            :fields="fields"
            :items-per-page="20"      
            :active-page="activePage"
            :pagination="{ doubleArrows: true, align: 'center'}"
            @page-change="pageChange"
            :loading=loading
          >                         
              
              <td slot="Lenses" slot-scope="{item}">
                <ul id="array-rendering">
                  <li :key="lense" v-for="lense in item.Lenses">
                    {{ lenses[lense.substring(0, 18)] }}
                  </li>
                </ul>
              </td> 
              <td slot="ImprovementStatus" slot-scope="{item}" >                  
                  {{ getStatus(item.ImprovementStatus) }}                  
              </td> 
              <td slot="UpdatedAt" slot-scope="{item}" >                  
                {{ convertDate(item.UpdatedAt) }}
              </td> 
               
              <td slot="workload_view" slot-scope="{item}">
                  <CButton v-on:click="viewWorkload(item)" :id="item.WorkloadId" block color="dark">
                    <CIcon name="cil-magnifying-glass"/>
                  </CButton>
              </td>    
              <td slot="workload_history_view" slot-scope="{item}">
                  <CButton v-on:click="viewWorkloadHistory(item)" :id="item.WorkloadId" block color="dark">
                    <CIcon name="cil-chart-line"/>
                  </CButton>
              </td>                            
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal
      :title="viewWorkloadItemName"
      color="dark"
      :show.sync="viewWorkloadHistoryModal"
      size="xl"
    >
        <p class="text-center" v-show="viewModalLoading">
          <CSpinner />
        </p >   
        <span v-show="!viewModalLoading">                
          <CCard>
            <CCardHeader>
              <strong>Risk Count History</strong>
            </CCardHeader>
         
            <CCardBody>  
              <CSelect
                style="width:150px"
                class="float-right"
                placeholder="Filter by..."
                :options="filteRiskHistoryBarChart"                
                disabled
                >
                </CSelect>                
              <CChartBar
                  style="height:400px"
                  :datasets="riskHistoryBarChartDataset"
                  :labels="riskHistoryBarChartLabels"
                  :options="{ maintainAspectRatio: false, scales: { xAxes: [{ stacked: true } ], yAxes: [{ stacked: true } ] } }"
                />        
            </CCardBody>    
          </CCard>
        </span>

      <div slot="footer" class="w-100">
          <CButton @click="viewWorkloadHistoryModal = false"  color="light" class="ml-1 mr-1 float-right col-sm-2" >Close</CButton>  
      </div>
    </CModal>    
    <CModal
      :title="viewWorkloadItemName"
      color="dark"
      :show.sync="viewModal"
      size="xl"
    >
        <p class="text-center" v-show="viewModalLoading">
          <CSpinner />
        </p >   
        <span v-show="!viewModalLoading">      
          <CCard>
              <CRow class="form-group" >
                <CCol :col="6">
                  <CCardHeader>
                    <strong>Review Owner</strong>
                  </CCardHeader>
                  <CCardBody>
                    {{ viewWorkloadItem.ReviewOwner }}
                  </CCardBody>                
                </CCol>
                <CCol :col="6">
                  <CCardHeader>
                    <strong>Updated</strong>
                  </CCardHeader>
                  <CCardBody>
                    {{ convertDate(viewWorkloadItem.UpdatedAt) }}
                  </CCardBody>
                </CCol>
              </CRow>
          </CCard>           
          <CCard>
            <CCardHeader>
              <strong>Description</strong>
            </CCardHeader>
            <CCardBody>
              {{ viewWorkloadItem.Description }}
            </CCardBody>
          </CCard>
          <CCard>
              <CRow class="form-group" >
                <CCol :col="6">
                  <CCardHeader>
                    <strong>Environment</strong>
                  </CCardHeader>
                  <CCardBody>
                    {{ viewWorkloadItem.Environment }}
                  </CCardBody>                
                </CCol>
                <CCol :col="6">
                  <CCardHeader>
                  <strong>AWS Regions</strong>
                  </CCardHeader>
                  <CCardBody>
                    <ul id="array-rendering">
                      <li :key="region" v-for="region in viewWorkloadItem.AwsRegions">
                        {{ region }}
                      </li>
                    </ul>
                  </CCardBody>                
                </CCol>
              </CRow>
          </CCard>             
          <CCard>
              <CRow class="form-group" >
                <CCol :col="6">
                  <CCardHeader>
                    <strong>Lenses</strong>
                  </CCardHeader>
                  <CCardBody>
                    <ul id="array-rendering">
                      <li :key="lense" v-for="lense in viewWorkloadItem.Lenses">
                        {{ lenses[lense] }}
                      </li>
                    </ul>
                  </CCardBody>                
                </CCol>
                <CCol :col="6">
                  <CCardHeader>
                    <strong>Pillar Priorities</strong>
                  </CCardHeader>
                  <CCardBody>
                    <ul id="array-rendering">
                      <li :key="pillar" v-for="pillar in viewWorkloadItem.PillarPriorities">
                        {{ pillars[pillar] }}
                      </li>
                    </ul>
                  </CCardBody>                
                </CCol>
              </CRow>
          </CCard>   
          <CCard>
            <CCardHeader>
              <strong>Risk Count</strong>
            </CCardHeader>
            <CCardBody>
              <CChartPie
                  :datasets="riskPieDataset"
                  :labels="riskPieLabels"
                />        
            </CCardBody>                             
          </CCard>
        </span>

      <div slot="footer" class="w-100">
          <CButton @click="viewModal = false"  color="light" class="ml-1 mr-1 float-right col-sm-2" >Close</CButton>  
      </div>
    </CModal>    
  </CRow>
</template>

<script>
import WorkloadService from '@/services/workload.services';
import LenseService from '@/services/lense.services';
import moment from 'moment'
import { CChartPie, CChartBar } from '@coreui/vue-chartjs'


export default {
  components: { CChartPie, CChartBar },
  data() {
    return {
      workloads: [],
      fields: [
        { key: 'WorkloadName', label: 'Name', _style:'width:20%' },
        { key: 'Lenses', label: 'Lenses', _style:'width:30%' },
        { key: 'ImprovementStatus', label: 'Status', _style:'width:20%' },
        { key: 'UpdatedAt', label: 'Updated', _style:'width:20%' },
        { key: 'workload_view', label: '', _style: 'width:1%', sorter: false, filter: false },
        { key: 'workload_history_view', label: '', _style: 'width:1%', sorter: false, filter: false }
      ],
      lenses: {},
      pillars: {},
      loading: true,
      viewModal: false,
      viewWorkloadHistoryModal: false,
      viewModalLoading: true, 
      viewWorkloadItemName: "",
      viewWorkloadItem: {},
      activePage: 1,
      riskPieBackgroundColors: [
            '#1c7685',
            '#cc0000',
            '#ff9900',
            '#38761d',
            '#707272'
      ],
      riskPieLabels: [],
      riskPieDataset:  [],
      riskHistoryBarChartLabels: [],
      riskHistoryBarChartDataset:  [],
      filteRiskHistoryBarChart: [
        '1 Month',
        '3 Months',
        '6 Months',
        '12 Months'
      ]
      
    };
  },
  methods: {
    convertDate(date) {
      return moment(date).format('DD.MM.YYYY - H:mm:ss ')
    },    
    getStatus (status) {
      switch (status) {
        case 'NOT_APPLICABLE': return 'Not applicable'
        case 'NOT_STARTED': return 'Not started'
        case 'IN_PROGRESS': return 'In progress'
        case 'COMPLETE': return 'Complete'
        case 'RISK_ACKNOWLEDGED': return 'Risk acknowledged'
        default: 'Unknown'
      }
    }, 
    getRisk (risk) {
      switch (risk) {
        case 'HIGH': return 'High'
        case 'MEDIUM': return 'Medium'
        case 'NONE': return 'Well-Architected'
        case 'UNANSWERED': return 'Unanswered'
        case 'NOT_APPLICABLE': return 'Not applicable'
        default: 'Unanswered'
      }
    },       
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    } ,
    async viewWorkload(workload) {
      this.viewModalLoading = true;
      this.viewWorkloadItemName = workload.WorkloadName;
      this.viewModal = true;

      await WorkloadService.getWorkload(workload.WorkloadId).then(
        response => { 
          this.viewWorkloadItem = response
        },
        error => {   
          console.log(error)   
        }
      )
      var labels = [];
      var data = [];

      for (var key in this.viewWorkloadItem.RiskCounts) {
        labels.push(this.getRisk(key))
        data.push(this.viewWorkloadItem.RiskCounts[key])
      }
      console.log(data)

      this.riskPieDataset=[
        {
          backgroundColor: this.riskPieBackgroundColors,
          data: data
        }
      ];

      this.riskPieLabels=labels;
      this.viewModalLoading = false;
      
    },
    async viewWorkloadHistory(workload) {
      this.viewModalLoading = true;
      this.viewWorkloadItemName = workload.WorkloadName;
      this.viewWorkloadHistoryModal = true;

      await WorkloadService.getWorkloadHistory(workload.WorkloadId).then(
        response => { 
          this.viewWorkloadHistoryItem = response
        },
        error => {   
          console.log(error)   
        }
      );
      var labels = [];
      var data = {
        HIGH: [],
        MEDIUM: [],
        NONE: [],
        UNANSWERED: []
      };

      for (const [key, value] of this.viewWorkloadHistoryItem.Data.entries()) {      
        labels.push(this.convertDate(value.UpdatedAt))
        data.HIGH.push(value.RiskCounts.HIGH)
        data.MEDIUM.push(value.RiskCounts.MEDIUM)
        data.NONE.push(value.RiskCounts.NONE)
        data.UNANSWERED.push(value.RiskCounts.UNANSWERED)
      }

      this.riskHistoryBarChartDataset=[
        {
          data: data.HIGH,
          backgroundColor: '#cc0000',
          label: 'High',
        },
        {
          data: data.MEDIUM,
          backgroundColor: '#ff9900',
          label: 'Medium',
        },                  
        {
          data: data.NONE,
          backgroundColor: '#38761d',
          label: 'Well-Architcted',
        },
        {
          data: data.UNANSWERED,
          backgroundColor: '#707272',
          label: 'Unanswered',
        }
      ];

      this.riskHistoryBarChartLabels=labels;
      this.viewModalLoading = false;
      
    },    
  },

  created() { },
  mounted() {
     LenseService.getLenses().then(
      response => { 
        this.lenses = response;
      },
      error => {   
        console.log(error)   
      }      
    )      
    LenseService.getPillars().then(
      response => { 
        this.pillars = response;
      },
      error => {   
        console.log(error)   
      }      
    )       
    WorkloadService.getWorkloads().then(
      response => { 
        this.workloads = response;
        this.loading = false;
      },
      error => {   
        console.log(error)   
      }      
    )
  },

  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};

</script>
