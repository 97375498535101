import api from './api';


class WorkloadService {
    async getWorkloads() {
        var workloads_list = {}
        const response = await api.get("/workload");
  
        workloads_list = response.data.body.WorkloadSummaries;        
        
        return workloads_list;
    } 
    async getWorkload(id) {
        var workload_dict = {}
        const response = await api.get("/workload/"+id);
  
        workload_dict = response.data.body;        
        
        return workload_dict;
    } 
    async getWorkloadHistory(id) {
        var workload_history_data = {}
        const response = await api.get("/workload/"+id+"/history");

        workload_history_data = response.data;        
        
        return workload_history_data;
    } 

  }
  
  export default new WorkloadService();